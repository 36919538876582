<template>
  <UserForm v-if="user" :user="user" :isEdit="true"/>
</template>

<script>
import store from "@/modules/Shared/Store/store";
import UserForm from "@/modules/Admin/Components/UserForm.vue";
import UsersService from "@/modules/Admin/Services/UsersService";

export default {
  name: "EditUserPage",
  components: {UserForm},

  data() {
    return {
      user: null,
    }
  },
  async beforeRouteEnter (to, from, next) {
      const user = await UsersService.getUser(to.params.uuid);
      await store.dispatch('setRouteElement', user)
      next(vm => vm.user = user)
  },
};
</script>
